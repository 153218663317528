import {MDXProvider} from '@mdx-js/react';
import {Analytics} from '@vercel/analytics/react';
import {DefaultSeo} from 'next-seo';
import type {AppProps} from 'next/app';
import Code from '../components/Code';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import {NextQueryParamProvider} from 'next-query-params';
import {CookieConsentBanner} from '@polarsignals/components';

import '@fontsource/open-sans';
import './App.scss';
import './globals.scss';

const components = {
  code: Code,
};

function MyApp({Component, pageProps, router}: AppProps) {
  console.log('router', router);
  return (
    <NextQueryParamProvider>
      <MDXProvider components={components}>
        <DefaultSeo
          title="Discover the Performance Engineer in you. | Polar Signals"
          description="Polar Signals Cloud is an always-on, zero-instrumentation continuous profiling for CPU, GPU, and Memory that helps improve performance, understand incidents, and lower infrastructure costs.
"
          openGraph={{
            type: 'website',
            locale: 'en_IE',
            url: 'https://polarsignals.com/',
            site_name: 'Discover the Performance Engineer in you. | Polar Signals',
            description:
              'Polar Signals Cloud is an always-on, zero-instrumentation continuous profiling for CPU, GPU, and Memory that helps improve performance, understand incidents, and lower infrastructure costs.',
            title: 'Discover the Performance Engineer in you. | Polar Signals',
            images: [{url: `${router.basePath}/og-image.png`}],
          }}
          twitter={{
            handle: '@PolarSignalsIO',
            site: '@PolarSignalsIO',
            cardType: 'summary_large_image',
          }}
          additionalLinkTags={[
            {rel: 'icon', href: '/favicon.png'},
            {
              rel: 'alternate',
              type: 'application/rss+xml',
              href: 'https://www.polarsignals.com/rss.xml',
            },
          ]}
          canonical={`https://www.polarsignals.com${router.asPath.split('?')[0]}`}
        />

        <Navbar />

        <Component {...pageProps} />
        <Analytics />
        <Footer />
        <CookieConsentBanner />
      </MDXProvider>
    </NextQueryParamProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
