import Highlight, {defaultProps, Language} from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';

// This is a working list of languages that need to be transformed to be prism compatible.
// For some reason, the language names in Sanity are different from the ones in prism-react-renderer
// So we need to transform them to be compatible. Lanuage list for prism-react-renderer can be found here: https://github.com/FormidableLabs/prism-react-renderer/blob/master/packages/generate-prism-languages/index.ts#L9-L23
// and the language list for Sanity can be found here: https://github.com/sanity-io/code-input/blob/main/src/codemirror/defaultCodeModes.ts
const transformLanguageToPrismCompatible = (language: string): Language => {
  switch (language) {
    case 'golang':
      return 'go';
    case 'sh':
      return 'bash';
    default:
      return language as Language;
  }
};

export default function Code({children, className, language}) {
  // Check if this is a code block by looking for the className pattern that MDX adds
  const isCodeBlock =
    className?.includes('language-') || className?.includes('hljs-') || !!language;

  if (!language) {
    language = className?.replace(/language-/, '') || 'text'; // Default to 'text' for plain text code blocks
  }

  // Only treat it as inline code if it's not a code block
  if (!isCodeBlock) {
    return (
      <code className="bg-gray-100 dark:bg-gray-800 text-sm text-gray-900 dark:text-gray-100 rounded px-1 py-0.5 inline-block">
        {children}
      </code>
    );
  }

  // For code blocks, use syntax highlighting
  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      code={children.trim()}
      language={transformLanguageToPrismCompatible(language)}
    >
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre
          className={className}
          style={{
            ...style,
            marginTop: 20,
            marginBottom: 20,
            padding: 16,
          }}
        >
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({line, key: i})} className="text-[15px] leading-[22px]">
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}
