import cx from 'classnames';

import type {SpinnerProps} from '@parca/components/dist/Spinner';
import LogoSymmetric from './logo-symmetric.svg';

const Spinner = ({
  size = 50,
  className = '',
  paddingClasses = '',
  loadingText = 'Loading',
}: SpinnerProps) => {
  return (
    <div
      className={cx('flex h-full w-full flex-col items-center justify-center', paddingClasses, {
        [className]: !!className,
        [paddingClasses]: !!paddingClasses,
      })}
    >
      <LogoSymmetric className="motion-safe:animate-spin-with-pulse" width={size} height={size} />
      <p className="block motion-safe:hidden">{loadingText}</p>
    </div>
  );
};

export default Spinner;
